<template>
  <div class="impairex-select2 text-gray-600">
    <vSelect
      v-model="selectedInput"
      :options="arrayList"
      :disabled="disabled"
      :get-option-label="getDisplayLabel"
      :required="required"
      @option:deselected="emitDeselect"
      @search="inputSearch"
      @open="arrowState = ''"
      @close="arrowState = 'transform-left'"
      @option:selected="emitSelecting"
    >
      <template #header>
        <label class="text-gray-600">
          {{ labelText }}
        </label>
        <BaseRequiredLabel v-if="required" />
      </template>

      <template #list-footer>
        <slot name="list-footer"></slot>
      </template>

      <template #no-options>
        <slot name="no-options"></slot>
      </template>

      <template #option="option">
        <slot name="option" v-bind="option">{{ option.first_name }}</slot>
      </template>

      <template #open-indicator="{ attributes }">
        <img
          v-bind="attributes"
          :class="arrowState"
          src="@/assets/svg/arrow-down.svg"
          alt="logo"
        />
      </template>

      <template #spinner="{ loading }">
        <div
          v-if="loading"
          class="spinner-border text-primary ms-2 spinner-border-sm"
          small
          role="status"
        />
      </template>
    </vSelect>
  </div>
</template>

<script>
//https://vuejsexamples.com/a-vue-component-for-a-simple-searchable-dropdown/

import vSelect from 'vue-select'
import _ from 'lodash'
import BaseRequiredLabel from '@/components/Base/BaseRequiredLabel.vue'

export default {
  name: 'Select2',
  components: { vSelect, BaseRequiredLabel },
  props: {
    tpdMode: { type: Boolean, default: false },
    dataArray: { type: Array, default: () => [] },
    dropdownPlaceholder: { type: String, default: 'searching...' },
    apiCallName: {
      type: String,
      default: null,
    },
    labelText: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Number, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'selecting', 'deselecting', 'addNew', 'input'],
  data() {
    return {
      arrayList: this.dataArray,
      arrowState: '',
    }
  },

  computed: {
    selectedInput: {
      get() {
        return this.modelValue
      },
      // setter
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },

  methods: {
    // emitSelected() {
    //   let value = this.selectedInput

    // },

    getDisplayLabel(val) {
      if (val.id) {
        return this.inputName === 'Referrer'
          ? `ID:${val.id} | ${val.first_name} | ${val.email}`
          : this.inputName === 'Patient'
          ? `ID:${val.id} | ${val.first_name} | ${
              val.gender ? val.gender : val.date_of_birth
            }`
          : this.inputName === 'Nurse'
          ? `Nurse ${val.first_name}  ${val.last_name}`
          : this.inputName === 'Doctor'
          ? `Doctor ${val.first_name}  ${val.last_name}`
          : this.inputName === 'Specialist'
          ? `Specialist ${val.first_name}  ${val.last_name}`
          : val.id
      } else return ''
    },

    emitSelecting() {
      this.$emit('selecting')
    },

    emitAddNew() {
      this.$emit('addNew')
    },

    emitDeselect() {
      this.selectedInput = null
    },

    inputSearch(search, loading) {
      if (search.length) {
        //hide arrow when loading
        this.arrowState = 'd-none'
        loading(true)

        this.search(loading, search)
      }
    },

    search: _.debounce(function (loading, search) {
      this.apiListCall(search)
      loading(false)
      this.arrowState = ''
    }, 400),

    apiListCall(searchVal) {
      let payload = { name: searchVal }

      this.$store.dispatch(this.apiCallName, payload).then((response) => {
        let data = response.data
        this.arrayList = data.results
      })
    },
  },
}
</script>

<style lang="scss">
.impairex-select2 {
  .vs--disabled {
    label {
      color: rgb(192, 192, 192) !important;
    }

    .vs__dropdown-toggle {
      background: rgb(212, 212, 212) !important;

      .vs__search {
        background: rgb(212, 212, 212) !important;
      }
    }
  }

  .v-select {
    width: 100%;
    position: relative;
    .vs__dropdown-toggle {
      position: relative;
      font-size: 14px;
      width: 100%;
      max-height: 38px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      background: none;
      border: 1px solid rgb(211, 211, 211);
      border-radius: 5px;
      white-space: normal;

      .vs__search {
        appearance: none;
        border: 1px solid transparent;
        border-left: none;
        outline: none;
        box-shadow: none;
        padding: 3px 10px;
        background: none;
        width: auto;
        color: rgb(73, 73, 73);
        max-width: 100%;
        flex-grow: 1;
        z-index: 1;

        &::placeholder {
          font-weight: 300;
        }
      }

      .vs__selected-options {
        display: flex;

        padding: 8px;
        position: relative;

        .vs__selected {
          display: flex;
          align-items: center;
          margin-bottom: -3px;
          padding: 0 1em;
          z-index: 0;
        }
      }

      .vs__actions {
        display: flex;
        align-items: center;
        padding: 0 10px;

        .vs__open-indicator {
          transition: all 450ms;
        }
      }
    }

    .vs__clear {
      margin: 0 12px;
      border-radius: 5px;
      border: 0;
      background-color: rgb(235, 235, 235);

      svg {
        path {
          fill: rgb(145, 145, 145);
        }
      }
    }

    .vs__dropdown-menu {
      position: absolute;
      overflow: hidden;
      z-index: 10;
      width: 100%;
      font-size: 14px;
      background: white;
      border: 0;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

      border-radius: 0 0 5px 5px;
      border-top-style: none;
      box-sizing: border-box;
      display: block;
      list-style: none;
      margin: 0;
      min-width: auto;
      overflow-y: auto;
      padding: 10px;
      text-align: left;
      width: 100%;
    }
  }
}

.transform-left {
  transform: rotate(90deg);
}
</style>
